import update from 'immutability-helper';

export default class Customer {
  constructor() {
    this.customers = [];
    this.columns = [];
    this.isLoading = false;
  }

  setCurrentCustomers(customersList) {
    return update(this, {
      customers: {
        $set: customersList,
      },
    });
  }

  setCurrentColumns(columns) {
    return update(this, {
      columns: {
        $set: columns,
      },
    });
  }

  toggleLoading() {
    return update(this, {
      isLoading: {
        $set: !this.isLoading,
      },
    });
  }
}
