/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';

export default class EditableLinksField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      links: props.initialValue.value,
      addLinkShown: false,
      linkToSave: '',
    };
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
  }

  save() {
    const { _updateOrder, initialValue } = this.props;
    const { links, linkToSave } = this.state;
    const newLinks = [...links];
    if (linkToSave) {
      newLinks.push(linkToSave);
    }
    this.setState({
      linkToSave: '',
      links: newLinks,
    }, () => {
      // eslint-disable-next-line react/destructuring-assignment
      _updateOrder(initialValue.idInDb, initialValue.id, this.state.links);
    });
  }

  delete(index) {
    const { links } = this.state;
    const newLinks = [...links];
    newLinks.splice(index, 1);
    this.setState({ links: newLinks }, () => this.save());
  }

  renderInput() {
    const { linkToSave } = this.state;

    return (
      <Input
        type="text"
        placeholder="Enter URL and press enter to submit"
        autoFocus
        value={linkToSave}
        onChange={(e) => this.setState({ linkToSave: e.target.value })}
        onPressEnter={() => this.save()}
        onBlur={() => this.setState({ linkToSave: '', addLinkShown: false })}
      />
    );
  }

  renderAddLinkButton() {
    return (
      <Button
        type="primary"
        icon="plus"
        size="small"
        onClick={() => {
          this.setState({ addLinkShown: true });
        }}
      >
        Add link
      </Button>
    );
  }

  render() {
    const {
      links, addLinkShown,
    } = this.state;

    return (
      <div>
        {links.map((link, index) => (
          <div style={{ marginBottom: '.6em' }} key={`link-${index}`}>
            <Icon type="paper-clip" />
            <a
              href={link}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </a>
            <Icon type="delete" style={{ float: 'right' }} onClick={() => this.delete(index)} />
          </div>
        ))}

        {
          addLinkShown
            ? this.renderInput() : this.renderAddLinkButton()
        }
      </div>
    );
  }
}

EditableLinksField.defaultProps = {
  initialValue: {},
};

EditableLinksField.propTypes = {
  _updateOrder: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    idInDb: PropTypes.string,
  }),
};
