import CustomerModel from '../DataModels/Customer';
import { notifyError } from '../../helpers/Notify';
import { _getCustomersAndColumns } from '../../api/Methods';
import { ORDERS_LOADED_COLUMNS } from './Orders';

// ///////////////
// Constants
// ///////////////
export const CUSTOMERS_LOADING = 'Customers/loading';
export const CUSTOMERS_LOADED = 'Customers/loaded';
export const CUSTOMERS_LOADING_ERROR = 'Customers/loadingError';


// ///////////////
// Reducers
// ///////////////
const customerDM = new CustomerModel();
export default (state = customerDM, action) => {
  switch (action.type) {
    case CUSTOMERS_LOADING:
      return state.toggleLoading();
    case CUSTOMERS_LOADED:
      return state
        .setCurrentCustomers(action.customers)
        .setCurrentColumns(action.columns)
        .toggleLoading();
    case CUSTOMERS_LOADING_ERROR:
      notifyError('Error', 'There was an error loading customers list');
      return state.toggleLoading();

    default:
      return state;
  }
};

// ///////////////
// Actions
// ///////////////
export const loadCustomers = () => (dispatch, getState) => {
  dispatch({ type: CUSTOMERS_LOADING });

  _getCustomersAndColumns((err, customers, columns) => {
    if (err || columns.data.length < 2) {
      return dispatch({ type: CUSTOMERS_LOADING_ERROR });
    }

    // work the customer id and also put the datas in values key
    // so it ressemble to the orders scheme
    const customersToSave = customers.data.map((customer) => ({
      values: {
        ...customer,
        type: 'customer',
        customerId: `${customer.identifier} - ${customer.name}`,
      },
      files: [],
      id: customer._id,
    }));

    // if orders columns are empty, add them also
    if (getState().Orders.columns.length < 1) {
      dispatch({
        type: ORDERS_LOADED_COLUMNS,
        columns: columns.data.length === 0 ? [] : columns.data[0].values,
      });
    }

    return dispatch({
      type: CUSTOMERS_LOADED,
      customers: customersToSave,
      columns: columns.data.length === 0 ? [] : columns.data[1].values,
    });
  });
};
