import path from './path';

const patchValues = (order) => {
  const newOrder = {
    ...order,
  };

  // re add the id inside the values
  newOrder.values._id = order.id;

  // also add the files here if exists
  newOrder.values.files = newOrder.files || [];

  // add missing link fields
  newOrder.values.travefyLink = newOrder.values.travefyLink || [];
  newOrder.values.driveLink = newOrder.values.driveLink || [];

  // temp fix (add backend url if not there yet)
  newOrder.values.files = newOrder.values.files.map((file) => {
    const newFileObj = { ...file };
    const backUrl = process.env.REACT_APP_BACKEND_URL;
    newFileObj.url = !newFileObj.url.includes(backUrl)
      ? `${path.join([backUrl, newFileObj.url])}` : newFileObj.url;

    return newFileObj;
  });

  return newOrder;
};

export default patchValues;
