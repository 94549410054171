import { cloneDeep } from 'lodash-es';

const computeOrderIds = (orders) => orders.map((order) => {
  const _order = cloneDeep(order);
  // eslint-disable-next-line max-len
  // _order.values.orderId = `W${_order.identifier || 0}-${(_order.values.name || '').split(' ')[0]}`;
  _order.values.orderId = `W${_order.identifier || 0}`;

  return _order;
});


export default computeOrderIds;
