import update from 'immutability-helper';
import { get, findKey } from 'lodash-es';

export default class Order {
  constructor() {
    this.orders = [];
    this.columns = [];
    this.loading = false;
    this.error = null;
    this.fieldsLoading = {};
    this.orderShowingInModal = null;
  }

  _findThisOrderKey(orderId) {
    return parseInt(findKey(this.orders, (order) => order.id === orderId), 10);
  }

  setColumns(cols) {
    return update(this, {
      columns: { $set: cols },
      loading: { $set: false },
    });
  }

  setOrders(ord) {
    return update(this, {
      orders: { $set: ord },
      loading: { $set: false },
    });
  }

  setOrderShowingInModal(order) {
    return update(this, {
      orderShowingInModal: {
        $set: order,
      },
    });
  }

  replaceOrder(orderId, newOrder) {
    const keyOfOrder = this._findThisOrderKey(orderId);
    if (keyOfOrder === undefined) {
      return this;
    }

    return update(this, {
      orders: {
        [keyOfOrder]: {
          $set: newOrder,
        },
      },
    });
  }

  setOneOrder(ordId, key, value) {
    const keyOfOrder = this._findThisOrderKey(ordId);
    if (keyOfOrder === undefined) {
      return this;
    }

    const newValues = {
      ...this.orders[keyOfOrder].values,
      [key]: value,
    };

    return update(this, {
      orders: {
        [keyOfOrder]: {
          values: { $set: newValues },
        },
      },
    });
  }

  setColumnsAndOrders(cols, ords) {
    const newSelf = this.setColumns(cols);
    return newSelf.setOrders(ords);
  }

  toggleLoading() {
    return update(this, { loading: { $set: !this.loading } });
  }

  setError(err) {
    return update(this, {
      loading: { $set: false },
      error: { $set: err },
    });
  }

  toggleFieldLoading(key) {
    return update(this, {
      fieldsLoading: {
        [key]: {
          $set: !get(this, `fieldsLoading.${key}`, false),
        },
      },
    });
  }

  removeFile(orderId, fileId) {
    const orderIndex = this._findThisOrderKey(orderId);
    if (orderIndex < 0) {
      // eslint-disable-next-line no-console
      console.error('Order index not found');

      return this;
    }

    const fileIndex = findKey(this.orders[orderIndex].files, (file) => file.id === fileId);

    return update(this, {
      orders: {
        [orderIndex]: {
          files: {
            $splice: [[fileIndex, 1]],
          },
        },
      },
    });
  }

  addFile(id, action, value = null) {
    const orderIndex = this._findThisOrderKey(id);
    if (orderIndex < 0) {
      // eslint-disable-next-line no-console
      console.error('Order index not found');

      return this;
    }

    return update(this, {
      orders: {
        [orderIndex]: {
          files: {
            $push: [value],
          },
        },
      },
    });
  }
}
