import { find } from 'lodash-es';

import { _getOrdersAndColumns, updateOrder as apiOrderUpdate } from '../../api/Methods';
import OrderModel from '../DataModels/Order';
import computeOrderIds from '../../helpers/computeOrderIds';
import patchValues from '../../helpers/patchValues';
// ///////////////
// Constants
// ///////////////
export const LOADING_ORDERS = 'Orders/loading_orders';
export const ORDERS_LOADED_SUCCESS = 'Orders/loaded_with_success';
export const ORDERS_NOT_LOADED_BUT_HAS_ERROR = 'Orders/not_loaded_has_error';
export const ORDERS_LOADED_COLUMNS = 'Orders/loaded_columns';

export const REQUEST_ORDER_FIELD_UPDATE = 'Orders/request_an_order_field_update';
export const ORDER_FIELD_UPDATE_SUCCESS = 'Orders/field_update_success';
export const ORDER_FIELD_UPDATE_FAILED = 'Orders/field_update_failed';
export const ORDER_FILES_ADDED = 'Orders/file_added';
export const ORDER_FILE_REMOVED = 'Orders/file_removed';
export const ORDER_SET_IN_MODAL = 'Orders/set_in_modal';

// ///////////////
// Reducers
// ///////////////
const orderDM = new OrderModel();
const Orders = (state = orderDM, action) => {
  switch (action.type) {
    // Order loading related
    case LOADING_ORDERS:
      return state.toggleLoading();
    case ORDERS_LOADED_SUCCESS:
      return state.setColumnsAndOrders(action.columns, action.orders);
    case ORDERS_LOADED_COLUMNS:
      return state.setColumns(action.columns);
    case ORDERS_NOT_LOADED_BUT_HAS_ERROR:
      return state.setError(action.error);

    // Order update related
    case REQUEST_ORDER_FIELD_UPDATE:
      return state.toggleFieldLoading(action.key);
    case ORDER_FIELD_UPDATE_SUCCESS:
      return state.toggleFieldLoading(action.key).replaceOrder(action.id, action.value);
    case ORDER_FIELD_UPDATE_FAILED:
      return state.toggleFieldLoading(action.key).setError(action.error);
    case ORDER_FILES_ADDED:
      return state.addFile(action.id, action.action, action.value);
    case ORDER_FILE_REMOVED:
      return state.removeFile(action.id, action.fileId);
    case ORDER_SET_IN_MODAL:
      return state.setOrderShowingInModal(action.order);


    default:
      return state;
  }
};

export default Orders;

// ///////////////
// Actions
// ///////////////
export const loadOrders = () => (dispatch) => {
  dispatch({ type: LOADING_ORDERS });

  _getOrdersAndColumns((error, orders, columns) => {
    if (error) {
      return dispatch({ type: ORDERS_NOT_LOADED_BUT_HAS_ERROR, error });
    }

    const orderWithIds = computeOrderIds(orders.data || []);

    return dispatch({
      type: ORDERS_LOADED_SUCCESS,
      orders: orderWithIds.map((order) => {
        const newOrder = patchValues(order);
        // newOrder.values._id = order.id;
        // // also add the files here if exists
        // newOrder.values.files = newOrder.files || [];
        // newOrder.values.links = newOrder.values.links || [];
        // // temp fix (add backend url if not there yet)
        // newOrder.values.files = newOrder.values.files.map((file) => {
        //   const newFileObj = { ...file };
        //   const backUrl = process.env.REACT_APP_BACKEND_URL;
        //   newFileObj.url = !newFileObj.url.includes(backUrl)
        //     ? `${path.join([backUrl, newFileObj.url])}` : newFileObj.url;

        //   return newFileObj;
        // });
        return newOrder;
      }),
      columns: columns.data.length === 0 ? [] : columns.data[0].values,
    });
  });
};

export const updateOrder = (id, key, value) => (dispatch, getState) => {
  dispatch({ type: REQUEST_ORDER_FIELD_UPDATE, key });

  // send the correct new order
  const currentOrders = getState().Orders;
  const newOrders = currentOrders.setOneOrder(id, key, value);
  const correctOrder = find(newOrders.orders, (order) => order.id === id);

  if (!correctOrder) {
    return dispatch({ type: ORDER_FIELD_UPDATE_FAILED, key, error: new Error('Order not found in redux store') });
  }

  // if accountManager is set, update the status to lead
  if (key === 'accountManager' && correctOrder.values.accountManager) {
    // if it never been in won status ever, make the change
    if (correctOrder.values.status !== 'Won') { correctOrder.values.status = 'Lead'; }
  }

  return apiOrderUpdate(id, correctOrder, (err, datas) => {
    if (err) {
      return dispatch({ type: ORDER_FIELD_UPDATE_FAILED, key, error: err });
    }

    return dispatch({
      type: ORDER_FIELD_UPDATE_SUCCESS,
      value: datas,
      id,
      key,
    });
  });
};

export const setOrderInModal = (order) => ({
  type: ORDER_SET_IN_MODAL,
  order,
});

export const appendFile = (orderId, fileObj) => (dispatch, getState) => {
  dispatch({
    type: ORDER_FILES_ADDED,
    action: 'add',
    id: orderId,
    value: fileObj,
  });

  const currentOrders = getState().Orders;
  const orderThatShouldBeInModal = find(
    currentOrders.orders,
    (order) => order._id === currentOrders.orderShowingInModal._id,
  );

  dispatch(setOrderInModal(orderThatShouldBeInModal));
};

export const removeFile = (orderId, fileId) => (dispatch) => {
  dispatch({
    type: ORDER_FILE_REMOVED,
    id: orderId,
    fileId,
  });
};
