import Basic from './Basic';
import Date from './Date';
import Category from './Category';
import Links from './Links';

export default (type, data, saveFunc) => {
  switch (type) {
    case 'basic':
      return Basic(data, saveFunc);
    case 'date':
      return Date(data, saveFunc);
    case 'category':
      return Category(data, saveFunc);
    case 'links':
      return Links(data, saveFunc);

    default:
      return Basic(data);
  }
};
