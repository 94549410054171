/* eslint-disable import/prefer-default-export */
import localStorage from 'local-storage';
import Axios from 'axios';
import history from '../helpers/history';
import client from '../helpers/HttpClient';
import { USER_OBJECT } from '../constants/localStorage';


export const _getOrdersPromiseOnly = () => client.get('api/orders_optimized');
export const _getCustomersPromiseOnly = () => client.get('api/customers?_sort=createdAt:DESC');
export const _getColumnsPromiseOnly = () => client.get('api/order-columns');

export const _getOrders = (cb) => _getOrdersPromiseOnly()
  .then(({ data }) => {
    if (cb && typeof cb === 'function') { cb(null, data); }
  })
  .catch(cb);


export const _getColumns = (cb) => _getColumnsPromiseOnly()
  .then(({ data }) => {
    if (cb && typeof cb === 'function') { cb(null, data); }
  })
  .catch(cb);


export const _getOrdersAndColumns = (cb) => Axios.all([
  _getOrdersPromiseOnly(),
  _getColumnsPromiseOnly(),
])
  .then(Axios.spread((orders, columns) => {
    cb(null, orders, columns);
  }))
  .catch(cb);

export const _getCustomersAndColumns = (cb) => Axios.all([
  _getCustomersPromiseOnly(),
  _getColumnsPromiseOnly(),
])
  .then(Axios.spread((customers, columns) => {
    cb(null, customers, columns);
  }))
  .catch(cb);

export const _getCommentsForOrder = (orderId) => client.get(`api/comments?order=${orderId}&_sort=createdAt:DESC`);

export const _sendComment = (comment) => client.post('api/comments', {
  ...comment,
});

export const _uploadFile = (datas, progressFunc) => client.post('upload', datas, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: progressFunc,
});

export const _removeFile = (fileId) => client.delete(`upload/files/${fileId}`);

export const getConfigs = () => ({
  logo: `${process.env.PUBLIC_URL}/assets/images/WAU_mg_Logo.png`,
  business: {
    name: 'WAU Madagascar',
  },
});

export const logout = () => {
  localStorage.clear();
  // update http client
  client.setDefaultHeader('Authorization', '');
  history.push('/');
};

export const login = (identifier, password, cb) => client.post('auth/local', {
  identifier, password,
})
  .then(({ data }) => {
  // store credentials in localstorage
    localStorage.set(USER_OBJECT, {
      ...data,
      connected: true,
    });
    // update http client
    client.setDefaultHeader('Authorization', `Bearer ${data.jwt}`);
    // Callback now that all is done
    cb(null, data);
    // change link
    history.push('/dashboard/main');
  })
  .catch((error) => {
    cb(error, null);
  });

export const updateOrder = (id, datas, cb) => client.put(`api/orders/${id}`, datas)
  .then(({ data }) => {
    cb(null, data);
  })
  .catch(cb);
