import React from 'react';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Checkbox from 'antd/es/checkbox';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';
import localStorage from 'local-storage';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash-es';

import { USER_OBJECT } from '../../constants/localStorage';
import { login } from '../../api/Methods';
import { notifyError, notifySuccess } from '../../helpers/Notify';

import './styles.css';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.formSubmit = this.formSubmit.bind(this);
  }

  formSubmit(e) {
    e.preventDefault();
    const { form } = this.props;

    form.validateFields((error, values) => {
      if (!error) {
        this.setState({ isLoading: true });
        login(values.username, values.password, (err) => {
          this.setState({ isLoading: false });
          if (err) {
            const errMessage = get(err, 'response.data.message.[0].messages[0].message',
              err.message);
            return notifyError('Error on login', errMessage);
          }
          return notifySuccess('Login success');
        });
      }
    });
  }

  render() {
    const { form, logo } = this.props;
    const { isLoading } = this.state;
    const userObj = localStorage.get(USER_OBJECT);
    // When user is already connected, redirect him to main page
    if (userObj && userObj.connected) {
      return (<Redirect to="/dashboard/main" />);
    }

    return (
      <>
        {logo ? (
          <img className="form-logo" src={logo} alt="Logo" />
        ) : null}
        <Card id="components-form-demo-normal-login">
          <Form className="login-form" onSubmit={(e) => this.formSubmit(e)}>
            <Form.Item>
              {form.getFieldDecorator('username', {
                rules: [{ required: true, message: 'Username is mandatory' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator('password', {
                rules: [{ required: true, message: 'Password is mandatory' }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox>Remember me</Checkbox>)}
              <a className="login-form-forgot" href="">
                Forgot password
              </a>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={isLoading}
              >
                Log in
              </Button>
              <p align="center">
                Or
                {' '}
                <a href="">register now!</a>
              </p>
            </Form.Item>
          </Form>
        </Card>
      </>
    );
  }
}

LoginForm.defaultProps = {
  logo: null,
};

LoginForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
  logo: PropTypes.string,
};

export default Form.create()(LoginForm);
