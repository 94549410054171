/* eslint-disable no-param-reassign */
import React from 'react';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Tag from 'antd/es/tag';
import { cloneDeep } from 'lodash-es';

import computeSpecialTypes from './computeSpecialTypes';

const composeColumns = (columnProps, openAnOrderFunc) => {
  const columns = cloneDeep(columnProps);

  return columns
    .filter((entry) => entry.visible)
    .map((entry, index) => {
      if (index === 0) {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          render: (text, record) => {
            if (record.status && record.status.toLowerCase() === 'new') {
              return (
                <>
                  {text}
                  {' '}
                  <Tag color="#f50">New</Tag>
                </>
              );
            }

            return text;
          },
        };
      }

      // handle special data types such as dates or relations
      entry = computeSpecialTypes(entry, openAnOrderFunc);

      // handle columns with sorters
      if (entry.sortDirections) {
        // eslint-disable-next-line no-param-reassign
        entry = {
          ...entry,
          sorter: (a, b) => a[entry.dataIndex]
            .toLowerCase()
            .localeCompare(b[entry.dataIndex].toLowerCase()),
        };
      }


      if (!entry.searchable) {
        return entry;
      }

      const input = React.createRef();

      // handle columns with search
      return {
        ...entry,
        filterDropdown: ({
        // eslint-disable-next-line react/prop-types
          setSelectedKeys, confirm, clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={input}
              placeholder="Search..."
            // value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => { confirm(); }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => { confirm(); }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
            Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                input.current.state.value = '';
              }}
              size="small"
              style={{ width: 90 }}
            >
            Reset
            </Button>
          </div>
        ),

        filterIcon: (filtered) => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),

        onFilter: (value, record) => record[entry.dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),

        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => input.current.select());
          }
        },
      };
    });
};

export default composeColumns;
