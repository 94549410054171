import React from 'react';
import PropTypes from 'prop-types';

const FieldCapsule = ({ label, uniqueKey, value }) => (
  <div key={uniqueKey} style={{ paddingBottom: '1em', display: 'flex' }}>
    <div style={{ fontWeight: 'bold' }}>
      {`${label} : `}
    </div>
    <div style={{ marginLeft: '8px', flexGrow: '1' }}>{value}</div>
  </div>
);

FieldCapsule.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  uniqueKey: PropTypes.any.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default FieldCapsule;
