import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import { loadOrders } from '../../redux/ActionsAndReducers/Orders';
import OrderTable from '../../components/OrderTable/OrderTable';
import { notifyError } from '../../helpers/Notify';

class Tours extends React.Component {
  componentDidMount() {
    const { _loadOrders } = this.props;
    _loadOrders();
  }

  render() {
    const {
      orders, columns, isLoading, err,
    } = this.props;
    if (err) {
      notifyError('Error on fetching datas');
    }

    const filteredOrders = orders.filter((order) => order.values.status === 'Won');
    // eslint-disable-next-line react/prop-types
    // const colsValues = columns.length === 0 ? [] : columns[0].values;
    return (
      <OrderTable dataSource={filteredOrders} columns={columns} loading={isLoading} />
    );
  }
}

Tours.defaultProps = {
  err: null,
};

Tours.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  _loadOrders: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadOrders: () => dispatch(loadOrders()),
});

const mapStateToProps = ({ Orders }) => ({
  isLoading: Orders.loading,
  err: Orders.error,
  orders: Orders.orders,
  columns: Orders.columns,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tours);
