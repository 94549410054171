/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Select from 'antd/es/select';
import PropTypes from 'prop-types';

const { Option } = Select;

class EditableCategoryField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      newValue: props.initialValue.value,
    };
    this.changeToEdit = this.changeToEdit.bind(this);
    this.save = this.save.bind(this);
    this.currentInput = null;
  }

  save(val) {
    this.setState({ editMode: false, newValue: val });
    const { _updateOrder, initialValue } = this.props;
    if (initialValue.value !== val) {
      _updateOrder(initialValue.idInDb, initialValue.id, val);
    }
  }

  changeToEdit() {
    const { editable } = this.props;
    if (!editable) {
      return;
    }

    this.setState({ editMode: true }, () => {
      this.currentInput.focus();
    });
  }

  displayValue() {
    const { newValue } = this.state;
    const { initialValue } = this.props;

    if (!initialValue.multiple) {
      return newValue || initialValue.value;
    }

    if (newValue) {
      return typeof newValue === 'string' ? newValue : newValue.join(', ');
    }

    return typeof initialValue.value === 'string' ? initialValue.value
      : (initialValue.value || []).join(', ');
  }

  render() {
    const { editMode, newValue } = this.state;
    const { editable, initialValue } = this.props;
    const InputData = (
      <Select
        style={{ width: 200 }}
        placeholder={`Select a ${initialValue.label}`}
        onBlur={this.save}
        defaultValue={newValue}
        ref={(re) => { this.currentInput = re; }}
        mode={initialValue.multiple ? 'multiple' : 'default'}
      >
        {initialValue.options.map((option) => (
          <Option key={option} value={option}>{option}</Option>
        ))}
      </Select>
    );

    return editMode && editable
      ? InputData
      : (
        <span
          onClick={() => this.changeToEdit()}
          role="button"
          className="clickable-text"
        >
          {this.displayValue()}

        </span>
      );
  }
}

EditableCategoryField.defaultProps = {
  initialValue: null,
  editable: true,
};

EditableCategoryField.propTypes = {
  initialValue: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    idInDb: PropTypes.string,
    id: PropTypes.string,
    multiple: PropTypes.bool,
  }),
  editable: PropTypes.bool,
  _updateOrder: PropTypes.func.isRequired,
};

export default EditableCategoryField;
