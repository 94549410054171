import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadCustomers } from '../../redux/ActionsAndReducers/Customers';
import OrderTable from '../../components/OrderTable/OrderTable';

class CustomersScreen extends React.Component {
  componentDidMount() {
    const { _loadCustomers } = this.props;
    _loadCustomers();
  }

  render() {
    const {
      customers, columns, isLoading,
    } = this.props;

    return (
      <OrderTable
        dataSource={customers}
        columns={columns}
        loading={isLoading}
      />
    );
  }
}

CustomersScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  _loadCustomers: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadCustomers: () => dispatch(loadCustomers()),
});

const mapStateToProps = ({ Customers }) => ({
  isLoading: Customers.isLoading,
  customers: Customers.customers,
  columns: Customers.columns,
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersScreen);
