import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import NotFound404 from '../errors/404';
import DashboardLayout from '../../components/DashboardLayout/Layout';
import Main from './main';
import Tours from './tours';
import Customers from './customers';
import RestrictedComponent from '../../hoc/RestrictedComponent';

const RestrictedMain = () => (<RestrictedComponent Component={Main} />);
const RestrictedTours = () => (<RestrictedComponent Component={Tours} />);
const RestrictedCustomers = () => (<RestrictedComponent Component={Customers} />);

const routeDashboard = ({ match }) => (
  <DashboardLayout>
    <Switch>
      <Route path={`${match.path}/main`} component={RestrictedMain} />
      <Route path={`${match.path}/tours`} component={RestrictedTours} />
      <Route path={`${match.path}/customers`} component={RestrictedCustomers} />

      <Route component={NotFound404} />
    </Switch>
  </DashboardLayout>
);

routeDashboard.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default routeDashboard;
