/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Spin from 'antd/es/spin';
import Empty from 'antd/es/empty';
import TimeAgo from 'react-timeago';
import Moment from 'moment';
import localStorage from 'local-storage';

import { USER_OBJECT } from '../../constants/localStorage';
import '../../timeline.scss';
import { loadCommentsForOrder, sendComment, clearAll } from '../../redux/ActionsAndReducers/Comments';

const { TextArea } = Input;
const format = 'ddd, MMMM Do YYYY';

class Activities extends React.Component {
  constructor(props) {
    super(props);

    this.sendAComment = this.sendAComment.bind(this);
  }

  componentDidMount() {
    const { load, orderId } = this.props;
    load(orderId);
  }

  componentWillUnmount() {
    const { clear } = this.props;
    clear();
  }

  addDatesToComments() {
    const { comments } = this.props;

    const commentsWithDates = [];
    let currentDate = '';

    const isDateToday = (date) => Moment(date).isSame(Moment(), 'day');

    comments.map((comment) => {
      const dateString = isDateToday(comment.createdAt)
        ? 'Today'
        : Moment(comment.createdAt).format(format);

      if (currentDate !== dateString) {
        // if it is a new date, add it to the array also
        commentsWithDates.push({
          isTitle: true,
          dateTitle: dateString,
        });
        currentDate = dateString;
      }

      // always append the current comment or it will be missing
      return commentsWithDates.push({ ...comment });
    });

    return commentsWithDates;
  }

  sendAComment(e) {
    e.preventDefault();

    const { orderId, send, form } = this.props;
    const { validateFields, resetFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const commentObj = {
          comment: values.comment,
          user: localStorage.get(USER_OBJECT).user._id,
          order: orderId,
        };

        resetFields();
        send(commentObj);
      }
    });
  }

  renderComments() {
    const commentsWithDates = this.addDatesToComments();
    if (commentsWithDates.length < 1) {
      return (
        <Empty description="No comments yet" />
      );
    }

    return commentsWithDates.map((comment, index) => (comment.isTitle ? (
      // eslint-disable-next-line react/no-array-index-key
      <article className="tl-item" key={index}>
        <div className="tl-body">
          <div className="tl-entry">
            <div className="tl-caption" style={{ marginLeft: '-37px' }}>
              <Button type="primary">{comment.dateTitle}</Button>
            </div>
          </div>
        </div>
      </article>
    ) : (
      // eslint-disable-next-line react/no-array-index-key
      <article className="tl-item" key={index}>
        <div className="tl-body">
          <div className="tl-entry">
            <div className="tl-time"><TimeAgo date={comment.createdAt} className="timeago" /></div>
            <div className="tl-icon icon-btn-round icon-btn icon-btn-thin btn-info">
              <Icon type="user" />
            </div>
            <div className="tl-content">
              <h4 className="tl-title text-primary">{comment.user.username || 'UNKNOWN'}</h4>
              <p>
                {comment.comment || ''}
              </p>
            </div>
          </div>
        </div>
      </article>
    )));
  }

  render() {
    const { loading, isSending, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <div style={{ marginBottom: '1.5em' }}>
          <Form onSubmit={this.sendAComment}>
            <Form.Item style={{ marginBottom: '4px' }}>
              {
              getFieldDecorator('comment', {
                rules: [
                  { required: true, message: 'You must enter a comment' },
                ],
              })(
                <TextArea rows={2} />,
              )
            }
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={isSending}>
                Add Comment
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Spin spinning={loading}>
          <section className="ui-timeline ui-timline-left">
            {
              this.renderComments()
            }
          </section>
        </Spin>
      </>
    );
  }
}

Activities.propTypes = {
  load: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  send: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  clear: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Comments }) => ({
  loading: Comments.isLoading,
  comments: Comments.comments,
  isSending: Comments.isSendingAComment,
});

const mapDispatchToProps = (dispatch) => ({
  load: (orderId) => dispatch(loadCommentsForOrder(orderId)),
  send: ({ comment, user, order }) => dispatch(sendComment(comment, user, order)),
  clear: () => dispatch(clearAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CommentEditor' })(Activities));
