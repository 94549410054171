/* eslint-disable no-param-reassign */
import React from 'react';
import patchValues from '../patchValues';

const computeSpecialTypes = (entry, openAnOrderFunc) => {
  // handle date columns
  if (entry.type === 'date') {
    entry = {
      ...entry,
      render: (text) => {
        if (typeof text === 'object') {
          const startText = text ? (text.start || '') : '-';
          const endText = text ? (text.end || '') : '-';
          return `${startText} - ${endText}`;
        }
        return text;
      },
    };
  }

  // handle relations
  if (entry.type === 'relation') {
    entry = {
      ...entry,
      render: (objs) => {
        const res = objs.map((obj) => {
          if (!openAnOrderFunc || typeof openAnOrderFunc !== 'function') {
            return obj.id;
          }

          const ordName = obj.values.name ? obj.values.name.split(' ')[0]
            : obj.id;
          const ordId = obj.identifier ? `${obj.identifier}-${ordName}` : obj.id;

          return (
            <a
              href="#!"
              key={obj.id}
              role="button"
              onClick={() => openAnOrderFunc(patchValues(obj))}
              style={{ display: 'inline-block', marginRight: '1em' }}
            >
              {ordId}
            </a>
          );
        });

        if (!openAnOrderFunc || typeof openAnOrderFunc !== 'function') {
          return res.join(', ');
        }

        return res;
      },
    };
  }

  return entry;
};

export default computeSpecialTypes;
