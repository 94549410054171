/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Input from 'antd/es/input';
import PropTypes from 'prop-types';
import Tag from 'antd/es/tag';

import './styles.css';

class EditableTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      newValue: props.initialValue.value,
    };
    this.changeToEdit = this.changeToEdit.bind(this);
    this.save = this.save.bind(this);
    this.currentInput = null;
  }

  save(e) {
    this.setState({ editMode: false, newValue: e.currentTarget.value });
    const { initialValue, _updateOrder } = this.props;
    if (initialValue.value !== e.currentTarget.value) {
      _updateOrder(initialValue.idInDb, initialValue.id, e.currentTarget.value);
    }
  }

  changeToEdit() {
    const { editable } = this.props;
    if (!editable) {
      return;
    }

    this.setState({ editMode: true }, () => {
      this.currentInput.focus();
    });
  }

  render() {
    const { editMode, newValue } = this.state;
    const { editable } = this.props;
    const InputData = (
      <Input
        onBlur={(e) => this.save(e)}
        defaultValue={newValue}
        ref={(input) => { this.currentInput = input; }}
      />
    );

    // handle yes/no values
    let valueToRender = newValue;
    const yesTag = (
      <Tag color="#3d9002">Yes</Tag>
    );
    const noTag = (
      <Tag color="#f50">No</Tag>
    );

    if ((typeof newValue === 'string' && ['false', 'true'].includes(newValue.toLowerCase().trim()))) {
      valueToRender = newValue.toLocaleLowerCase().trim() === 'true'
        ? yesTag : noTag;
    }

    if (typeof newValue === 'boolean') {
      valueToRender = newValue
        ? yesTag : noTag;
    }

    return editMode && editable
      ? InputData
      : (
        <span
          onClick={() => this.changeToEdit()}
          role="button"
          className="clickable-text"
        >
          {valueToRender}

        </span>
      );
  }
}

EditableTextField.defaultProps = {
  initialValue: {},
  editable: true,
};

EditableTextField.propTypes = {
  initialValue: PropTypes.shape({
    value: PropTypes.string,
    idInDb: PropTypes.string,
    id: PropTypes.string,
  }),
  editable: PropTypes.bool,
  _updateOrder: PropTypes.func.isRequired,
};


export default EditableTextField;
