import React from 'react';
import Upload from 'antd/es/upload';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import path from '../../helpers/path';
import { appendFile, removeFile } from '../../redux/ActionsAndReducers/Orders';
import { updateFile } from '../../redux/ActionsAndReducers/FileUploads';
import { _uploadFile, _removeFile } from '../../api/Methods';
import downloadUrl from '../../helpers/downloadUrl';

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line camelcase
    const { rdx_removeFile, order } = props;

    this.upload = this.upload.bind(this);
    this.uploadProps = {
      name: 'files',
      customRequest: ({
        file, onSuccess, onError, onProgress,
      }) => this.upload(file, onSuccess, onError, onProgress),
      method: 'POST',
      onDownload: (file) => {
        const { url } = file.originFileObj || file;
        const urlToDownload = !url ? get(file, 'response.data[0].url', '') : url;

        if (urlToDownload) { downloadUrl(urlToDownload); }
      },
      onRemove: (file) => {
        // api
        _removeFile(file.uid);
        // redux
        rdx_removeFile(order._id, file.uid);
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  upload(file, onSuccess, onError, onProgress) {
    const formData = new FormData();
    formData.append('files', file);
    // add references for attaching the file
    // eslint-disable-next-line camelcase
    const { order, rdx_pushFile } = this.props;
    formData.append('refId', order._id);
    formData.append('ref', 'order');
    formData.append('field', 'files');

    return _uploadFile(formData, (progressEvt) => {
      // handle progress
      const progress = Math.round((progressEvt.loaded * 100) / progressEvt.total);
      onProgress({ percent: progress });
    })
      .then((response) => {
        // const { url } = response.data[0];
        // add the file url
        // eslint-disable-next-line no-param-reassign
        // file.url = path.join([process.env.REACT_APP_BACKEND_URL, url], '/');
        // update also the local redux tree
        rdx_pushFile(order._id, response.data[0]);
        onSuccess(response);
      })
      .catch((err) => {
        onError(err);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  addHostAndProtocolToURI(uri) {
    if (!uri) return '';
    const hostAndProtocol = process.env.REACT_APP_BACKEND_URL;
    return path.join([hostAndProtocol, uri]);
  }

  render() {
    const { files } = this.props;
    const filesAlreadyUploaded = files ? files.map((file) => ({
      uid: file.id,
      name: file.name,
      status: 'done',
      url: this.addHostAndProtocolToURI(file.url),
    })) : [];
    return (
      <Upload
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.uploadProps}
        defaultFileList={filesAlreadyUploaded.reverse()}
      >
        <Button>
          <Icon type="upload" />
          {' '}
          Click to Upload
        </Button>
      </Upload>
    );
  }
}

FileUploader.defaultProps = {
  files: [],
};

FileUploader.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  rdx_pushFile: PropTypes.func.isRequired,
  rdx_updateFile: PropTypes.func.isRequired,
  rdx_removeFile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ FileUploads }) => ({
  files: FileUploads.currentFiles,
});

const mapDispatchToProps = (dispatch) => ({
  rdx_pushFile: (orderId, fileObject) => {
    dispatch(appendFile(orderId, fileObject));
    // dispatch(updateFile(fileObject));
  },
  rdx_updateFile: (file) => {
    dispatch(updateFile(file));
  },
  rdx_removeFile: (orderId, fileId) => dispatch(removeFile(orderId, fileId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
