import React from 'react';

import FieldCapsule from '../../hoc/FieldCapsule';
import EditableDateField from '../../components/EditableDateField/EditableDateField';

const Date = (data, saveFunc) => (
  <FieldCapsule
    label={data.label}
    uniqueKey={data.id}
    key={data.id}
    value={(
      <EditableDateField
        initialValue={data}
        _updateOrder={saveFunc}
      />
    )}
  />
);

export default Date;
