import Orders from './Orders';
import FileUploads from './FileUploads';
import Customers from './Customers';
import Comments from './Comments';

export default {
  Orders,
  FileUploads,
  Customers,
  Comments,
};
