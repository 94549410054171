/* eslint-disable no-multi-str */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash-es';
import { connect } from 'react-redux';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
// import { Editor } from '@tinymce/tinymce-react';

import Activities from '../Activities/Activities';
import Render from '../../helpers/OrderRenderers/Render';
import { updateOrder } from '../../redux/ActionsAndReducers/Orders';
import FileUploader from '../FileUploader/FileUploader';
import './styles.css';

const step3Keys = [
  'name',
  'email',
  'phone',
  'country',
  'accountManager',
  'status',
];

const step1Keys = [
  'specificDates',
  'numberOfDays',
  'wantsToTravelIn',
  'travellingDates',
  'numberOfAdults',
  'numberOfChildren',
];

const step2Keys = [
  'partsOfMG',
  'hotelStandards',
  'interestedIn',
  'tourDescription',
  'specialRequest',
];

const linksKeys = [
  'travefyLink',
  'driveLink',
];

const OrderDetails = ({
  contentToShow, columns, _updateOrder,
}) => {
  // small functions
  const renderDatas = (listOfKeys, allDatas) => listOfKeys.map((key) => {
    const config = find(columns, (c) => c.dataIndex === key);
    if (!config) {
      return null;
    }

    return Render(
      config.type,
      {
        label: config.title,
        value: typeof allDatas[key] === 'boolean' ? allDatas[key] : (allDatas[key] || ''),
        id: key,
        editable: key !== 'orderId',
        options: config.options || [],
        multiple: config.multiple || false,
        // eslint-disable-next-line react/prop-types
        idInDb: contentToShow._id,
      },
      _updateOrder,
    );
  }).filter((e) => !!e);

  const renderNotes = () => (
    <>
      <Activities orderId={contentToShow.id} />
    </>
  );


  // ///////////////////////////////////////

  const filteredDatas = {};
  Object.entries(contentToShow.values || [])
  // only show the ones having the columns tagged as form
    .filter(([key, value]) => {
      let correspondingColumn = find(columns, (c) => c.dataIndex === key);
      correspondingColumn = correspondingColumn || { tags: [] };
      return (correspondingColumn.tags || []).includes('form');
    })
    // regenerate the array into original object
    .forEach(([key, value]) => {
      filteredDatas[key] = value;
    });

  const gutterStyle = { padding: '8px' };

  return (
    <div className="order-details-container">
      <Row className="responsive-flex">
        <Col xs={24} md={12} lg={8} style={{ display: 'flex', flexDirection: 'column' }}>
          <Row>
            <Col span={24} style={gutterStyle}>
              <Card style={{ width: '100%' }}>
                {renderDatas(step3Keys, filteredDatas)}
              </Card>
            </Col>
            <Col span={24} style={gutterStyle}>
              <Card style={{ width: '100%' }}>
                {renderDatas(step1Keys, filteredDatas)}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={gutterStyle}>
              <Card style={{ width: '100%', flexGrow: '1' }}>
                <span><strong>File attachments : </strong></span>
                <FileUploader order={contentToShow} />
                <br />
                {renderDatas(linksKeys, filteredDatas)}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={16} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
            <Col span={24} style={gutterStyle}>
              <Card style={{ width: '100%' }}>
                {renderDatas(step2Keys, filteredDatas)}
              </Card>
            </Col>
            <Col span={24} style={{ ...gutterStyle, flexGrow: '1' }}>
              <Card style={{ width: '100%', height: '100%' }} className="notes-card">
                {renderNotes()}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

OrderDetails.defaultProps = {
  columns: [],
  contentToShow: null,
};

OrderDetails.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  _updateOrder: PropTypes.func.isRequired,
  contentToShow: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.shape({})),
    values: PropTypes.shape({
      notes: PropTypes.string,
    }),
    id: PropTypes.string,
  }),
};

const mapStateToProps = ({ Orders }) => ({
  contentToShow: Orders.orderShowingInModal,
  columns: Orders.columns,
});

const mapDispatchToProps = (dispatch) => ({
  _updateOrder: (id, key, value) => dispatch(updateOrder(id, key, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
