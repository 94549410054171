import React from 'react';

import FieldCapsule from '../../hoc/FieldCapsule';
import EditableLinksField from '../../components/EditableLinksField/EditableLinkFields';


const Links = (data, saveFunc) => (
  <FieldCapsule
    label={data.label}
    uniqueKey={data.id}
    key={data.id}
    value={(
      <EditableLinksField
        initialValue={data}
        _updateOrder={saveFunc}
      />
    )}
  />
);

export default Links;
