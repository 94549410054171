import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'antd/es/layout';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import { connect } from 'react-redux';
import { forOwn } from 'lodash-es';
import message from 'antd/es/message';

import history from '../../helpers/history';
import { getConfigs, logout } from '../../api/Methods';
import './styles.css';

const { Header, Content, Footer } = Layout;
const configs = getConfigs();
const { logo, business } = configs;
const year = (new Date()).getFullYear();

const getCurrentPageMenuIndex = () => {
  const url = window.location.href;
  const menus = ['main', 'tours', 'customers'];
  let res = null;
  menus.forEach((val, index) => {
    if (url.includes(val)) {
      res = index;
    }
  });

  return res.toString();
};

const DashboardLayout = ({ children, fieldUpdates }) => {
  // handle loading notification
  let stillUpdating = false;
  forOwn(fieldUpdates, (val) => {
    if (val) {
      if (!stillUpdating) {
        stillUpdating = true;
      }
    }
  });
  if (!window.loadingMessage && stillUpdating) {
    window.loadingMessage = message.loading('Updating order...', 0);
  } else if (window.loadingMessage && !stillUpdating) {
    // call to close the box
    window.loadingMessage();
    window.loadingMessage = null;
  }

  return (
    <Layout className="uos-layout">
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <div className="logo">
          <img src={logo} alt="Logo" style={{ height: '100%' }} />
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={[getCurrentPageMenuIndex()]}
          style={{ lineHeight: '64px', float: 'left' }}
        >
          <Menu.Item key="0" onClick={() => { history.push('/dashboard/main'); }}>Inbox</Menu.Item>
          <Menu.Item key="1" onClick={() => { history.push('/dashboard/tours'); }}>Tours</Menu.Item>
          <Menu.Item key="2" onClick={() => { history.push('/dashboard/customers'); }}>Customers</Menu.Item>
        </Menu>

        <Menu
          className="rightMenu"
          theme="light"
          mode="horizontal"
          style={{ lineHeight: '64px', float: 'right' }}
        >
          <Menu.Item onClick={logout}>
            <Icon type="logout" />
            {' '}
            Logout
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '40px 50px', marginTop: 64 }}>
        <div className="inner-layout-content" style={{ background: '#fff', padding: 24, minHeight: 380 }}>
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        {business.name}
        {' '}
        {year}
      </Footer>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
  fieldUpdates: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ Orders }) => ({
  fieldUpdates: Orders.fieldsLoading,
});

export default connect(mapStateToProps)(DashboardLayout);
