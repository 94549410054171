import React from 'react';

import FieldCapsule from '../../hoc/FieldCapsule';
import EditableTextField from '../../components/EditableTextField/EditableTextField';
import './styles.css';

const Basic = (data, saveFunction) => (
  <FieldCapsule
    label={data.label}
    uniqueKey={data.id}
    key={data.id}
    value={(
      <EditableTextField
        initialValue={data}
        editable={data.editable}
        _updateOrder={saveFunction}
      />
    )}
  />
);

export default Basic;
