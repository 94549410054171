import React from 'react';
import Modal from 'antd/es/modal';
import PropTypes from 'prop-types';

import './styles.css';

const FullscreenModal = ({
  visible, cancel, renderContent, children,
}) => (
  <Modal
    visible={visible}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    onCancel={cancel}
    footer={null}
    className="fullscreen-modal"
    destroyOnClose
  >
    {children || (renderContent ? renderContent() : null)}
  </Modal>
);

FullscreenModal.defaultProps = {
  children: null,
  renderContent: null,
};

FullscreenModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
  children: PropTypes.element,
};

export default FullscreenModal;
