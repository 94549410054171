/* eslint-disable class-methods-use-this */
import React from 'react';
import Table from 'antd/es/table';
import PropTypes from 'prop-types';
import { find } from 'lodash-es';
import { connect } from 'react-redux';

import { setOrderInModal } from '../../redux/ActionsAndReducers/Orders';
import { setFilesList } from '../../redux/ActionsAndReducers/FileUploads';
import FullscreenModal from '../FullscreenModal/FullscreenModal';
import composeColumns from '../../helpers/Table/composeColumns';
import OrderDetails from '../OrderDetails/OrderDetails';
import './styles.css';

const windowDIffCoeff = 225;

class OrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTextToggle: false,
      modalShown: false,
      // contentToShow: {},
      height: window.innerHeight - windowDIffCoeff,
    };

    this.handleChanges = this.handleChanges.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleChanges() {
    const { searchTextToggle } = this.state;
    this.setState({ searchTextToggle: !searchTextToggle });
  }

  handleWindowResize() {
    this.setState({ height: window.innerHeight - windowDIffCoeff });
  }

  convertDataSourceToTableDatas(dataSource) {
    const finalDatas = [];

    if (dataSource.length < 1) {
      return finalDatas;
    }

    return dataSource.map((order) => order.values);
  }

  openModalWithOrder(orderId) {
    const {
      dataSource, rdxSetOrderInModal, rdxSetFilesList,
    } = this.props;

    // find the correct record
    const orderToShow = typeof orderId === 'string' ? find(
      dataSource,
      (order) => order.id === orderId,
    ) : orderId;

    rdxSetOrderInModal(orderToShow);
    rdxSetFilesList(orderToShow.files || []);
    return this.setState({ modalShown: true });
  }

  render() {
    const {
      dataSource, columns, loading,
    } = this.props;
    const { modalShown, height } = this.state;
    const newCols = composeColumns(columns, (order) => this.openModalWithOrder(order));

    const OrderDetailsComponent = (
      <OrderDetails />
    );

    return (
      <>
        <FullscreenModal
          visible={modalShown}
          cancel={() => {
            this.setState({ modalShown: false });
          }}
        >
          {OrderDetailsComponent}
        </FullscreenModal>
        <Table
          columns={newCols}
          dataSource={this.convertDataSourceToTableDatas(dataSource)}
          className="order-table"
          scroll={{ x: 1000, y: height }}
          rowKey={(dt) => dt._id}
          onRow={(record) => ({
            onClick: () => {
              if (record.type === 'customer') {
                return false;
              }
              return this.openModalWithOrder(record._id);
            },
          })}
          pagination={false}
          rowClassName={(record) => (record.status && record.status.toLowerCase() === 'new' ? 'new-row' : '')}
          loading={loading}
        />
      </>
    );
  }
}

OrderTable.defaultProps = {
  dataSource: [],
  columns: [],
  loading: true,
};

OrderTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  rdxSetOrderInModal: PropTypes.func.isRequired,
  rdxSetFilesList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  rdxSetOrderInModal: (order) => dispatch(setOrderInModal(order)),
  rdxSetFilesList: (filesList) => dispatch(setFilesList(filesList)),
});

export default connect(null, mapDispatchToProps)(OrderTable);
