import { findKey } from 'lodash-es';
import update from 'immutability-helper';

export default class FileUpload {
  constructor() {
    this.currentFiles = [];
  }

  setCurrentFiles(filesList) {
    return update(this, {
      currentFiles: {
        $set: filesList,
      },
    });
  }

  updateFile(file) {
    const keyOfFile = findKey(this.currentFiles, (_file) => file.uid === _file.uid);
    if (keyOfFile === undefined || keyOfFile === null || keyOfFile < 0) {
      return update(this, {
        currentFiles: {
          $push: [file],
        },
      });
    }

    return update(this, {
      currentFiles: {
        [keyOfFile]: {
          $set: file,
        },
      },
    });
  }
}
